<template>
  <div class="m-section-header">
    <div class="m-section-header__top">
      <AHeading
        v-if="title"
        :level="titleLevel"
      >
        {{ title }}
      </AHeading>
      <template v-if="linkHref && linkText">
        <ALink
          v-if="!isClosed"
          :href="getUrl(linkHref, !linkHref.includes('.html'))"
          underline
        >
          {{ linkText }}
        </ALink>
        <span
          v-else
          class="link underline"
          @click="$router.push(getUrl(linkHref, !linkHref.includes('.html')))"
        >
          {{ linkText }}
        </span>
      </template>

      <AButton
        v-if="withButton && locale === 'ru'"
        size="lg"
        class="i-city-red"
        variant="secondary"
        @click.stop="mainStore.openModalCity"
      >
        {{ buttonContent }}
      </AButton>
    </div>
    <p v-if="subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import './SectionHeader.css'
import { useMainStore } from '@/stores'
import { useNavigate } from '@/composables/useNavigate'
import { useLocalization } from '#imports'

import type { PropType } from 'vue'
import type { HeaderLevel } from '@/components/ui-kit/a-heading/types'

import AHeading from '@/components/atoms/Heading/AHeading.vue'
import AButton from '@/components/atoms/Button/AButton.vue'
import ALink from '@/components/atoms/Link/ALink.vue'

defineProps({
  title: {
    type: String,
    default: ''
  },
  titleLevel: {
    type: [String, Number] as PropType<HeaderLevel>,
    default: 2
  },
  subtitle: {
    type: String,
    default: ''
  },
  linkHref: {
    type: String,
    default: ''
  },
  linkText: {
    type: String,
    default: ''
  },
  withButton: {
    type: Boolean,
    default: false
  },
  isClosed: {
    type: Boolean,
    default: false
  },
  buttonContent: {
    type: String,
    default: ''
  }
})

const { getUrl } = useNavigate()
const { locale } = useLocalization()
const mainStore = useMainStore()
</script>
